



























































































































































































































import _ from "lodash"
import { Vue, Component, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { GridOptions } from "ag-grid"
import { WorkBook } from "xlsx"
import { cx } from "@/types"
import xlsx from "xlsx"
import gql from "graphql-tag"
import Grid from "@/components/Grid.vue"
import PDFService from "@/services/pdf"
import s3 from "@/services/s3"
import CXService from "@/services/cx"
import Trunquee from "@/components/Trunquee.vue"
import TrunqueeCell from "@/components/cells/TrunqueeCell.vue"
import ProductHeader from "@/components/ProductHeader.vue"
import SupportCaseWorkflow from "@/components/SupportCaseWorkflow.vue"
import { Auth0UserProfile } from "auth0-js"
import BasicCommentFeed from "@/components/comments/basic/CommentFeedBasic.vue"
import getCommentsQuery from "@/graphql/get-case-comments.gql"
import WarrantyService from "../services/warranty"

@Component({
  name: "return-info",
  components: {
    Grid,
    Trunquee,
    ProductHeader,
    SupportCaseWorkflow,
    BasicCommentFeed,
  },
})
export default class ReturnInfo extends Vue {
  private readonly s3Service = new s3()
  private readonly pdfService = new PDFService()
  private readonly cxService = new CXService()
  private readonly warrantyService = new WarrantyService()
  readonly descriptionTruncateLines: number = 5
  unknown: boolean = false
  returnData: cx.Returns.Return | null = null
  returnItems: Array<cx.Returns.Item> | null = null
  displayFullDescription: boolean = false
  attachmentsMenu: boolean = false
  loadingPDF: boolean = false
  workflowStatusMenu: boolean = false
  rowData: Array<cx.Returns.Item> = []
  gridOptions: GridOptions = {}
  uploadingAttachment: boolean = false

  @Getter("user/getUser")
  user!: Auth0UserProfile

  created() {
    this.gridOptions = {
      gridAutoHeight: true,
      enableSorting: true,
      enableFilter: true,
      floatingFilter: false,
      animateRows: true,
      enableRangeSelection: true,
      enableColResize: true,
      colResizeDefault: "shift",
      suppressMovableColumns: true,
      toolPanelSuppressSideButtons: true,
      pagination: true,
      paginationPageSize: 10,
      getContextMenuItems: params => {
        return _.filter(params.defaultItems, item => item !== "toolPanel")
      },
      columnDefs: [
        {
          headerName: "Serial #",
          field: "serialNumber",
          editable: false,
          colId: "serialNumber",
          width: 100,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Item Number",
          field: "itemNumber",
          editable: false,
          colId: "itemNumber",
          width: 100,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          editable: false,
          colId: "quantity",
          width: 70,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Issue Type",
          field: "issue.type",
          editable: false,
          colId: "issueType",
          width: 100,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Issue",
          field: "issue.name",
          editable: false,
          colId: "issue",
          width: 100,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Disposition",
          field: "disposition",
          editable: false,
          colId: "disposition",
          width: 150,
          suppressMenu: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
      ],
    }
  }

  async mounted() {
    this.returnData = await this.cxService.getReturn(this.$route.params.id)
    setTimeout(() => {
      this.workflowStatusMenu = true
    }, 300)
    if (_.isNil(this.returnData)) {
      this.unknown = true
    }
  }

  get attachments() {
    return this.returnData!.attachments
  }

  get requiresTruncate(): boolean {
    if (!_.isNil(this.returnData) && _.isString(this.returnData.description)) {
      return (_.countBy(this.returnData.description)["\n"] || 0) > this.descriptionTruncateLines
    }
    return false
  }

  get truncatedDescription(): string | null {
    if (!_.isNil(this.returnData) && _.isString(this.returnData.description)) {
      return `${_.join(_.split(this.returnData.description, "\n", this.descriptionTruncateLines), "\n")}...`
    }
    return null
  }

  async populateGrid(): Promise<void> {
    if (this.returnItems && _.size(this.returnItems) > 0) {
      this.rowData = this.returnItems
      this.gridOptions.api!.updateRowData({ add: this.returnItems })
    }
  }

  async setupGrid(): Promise<void> {
    if (!_.isNil(this.returnData) && !_.isNil(this.returnData.id)) {
      this.returnItems = await this.cxService.getReturnItems(this.returnData.id)
      let populationPromise = this.populateGrid()
      if (this.returnData.productGroup.hasSerialNumber) {
        this.gridOptions.columnApi!.setColumnVisible("quantity", false)
      } else {
        this.gridOptions.columnApi!.setColumnsVisible(["serialNumber", "itemNumber"], false)
      }
      await populationPromise
    }
    this.gridOptions.api!.sizeColumnsToFit()
  }

  downloadInformation(): void {
    this.loadingPDF = true
    if (!_.isNil(this.returnData)) {
      this.buildPDF(_.assign(this.returnData, { rowData: this.returnItems }))
    } else {
      throw "missing return data for information download"
    }
  }

  /**
   * TODO: the loading spinner broke on typescript/version upgrade
   *   This needs to be refactored to work with the changes to the Vue render engine (maybe upgrade to Vuetfiy 2.0?)
   *   Just force updating for now because it is a user-triggered method and won't be costly
   *   Remove eslint disable
   */
  async downloadAttachment(attachment: cx.Returns.Attachment) {
    if (!this.attachments) throw "no attachments to download!"

    attachment.loading = true
    this.$forceUpdate()
    try {
      await this.s3Service.downloadObject(attachment.key, attachment.name)
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
      this.$events.emit("show-snackbar", {
        text: `Failed to download ${attachment.name}`,
        color: "error",
      })
    } finally {
      attachment.loading = false // eslint-disable-line require-atomic-updates
      this.$forceUpdate()
    }
  }

  uploadAttachment(event: any) {
    const file: File = event.target.files[0]

    if (file.size > 26214400) {
      this.$events.emit("show-snackbar", {
        text: `File exceeds maximum size limit of 25MB`,
        color: "error",
      })
      return
    }

    this.uploadingAttachment = true
    this.cxService
      .createSupportCaseAttachment(this.returnData!, this.user, file)
      .then(result => {
        this.$events.emit("show-snackbar", {
          text: `Successfully uploaded ${file.name}`,
          color: "success",
        })
        this.attachments.push(result)
      })
      .catch(error => {
        console.error(error)
        this.$raven.captureException(error)
        this.$events.emit("show-snackbar", {
          text: `Failed to upload ${file.name}`,
          color: "error",
        })
      })
      .finally(() => {
        this.uploadingAttachment = false
      })
  }

  onFetchComments(offset?: number, limit?: number) {
    return this.$apollo.query({
      query: getCommentsQuery,
      variables: {
        supportCaseId: this.returnData!.salesforceId,
        limit: limit,
        offset: offset,
      },
    })
  }

  onCreateComment(textValue: string): Promise<any> {
    return this.cxService.createComment(
      this.returnData!.salesforceId,
      textValue,
      String(_.last(_.split(this.user.user_id, "|")))
    )
  }

  exportHook(workbook: WorkBook) {
    let ReturnInfo = this
    let sheetJson = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
    if (!ReturnInfo || !ReturnInfo.returnData) throw "supportCase is null or undefined"
    let referenceNumber = this.$route && this.$route.params ? this.$route.params.id || "" : ""
    _.forEach(sheetJson, row => {
      _.assign(row, {
        //TODO: do we want to change the Case Number to reference Number within the spreadsheet?
        "Case Number": referenceNumber,
        "Product Line": ReturnInfo.returnData!.productGroup ? ReturnInfo.returnData!.productGroup.name : "",
        "Requested Action": ReturnInfo.returnData!.action ? ReturnInfo.returnData!.action.name : "",
        "Customer Account": ReturnInfo.returnData!.account ? ReturnInfo.returnData!.account.name : "",
        "Billing Address Name": ReturnInfo.returnData!.location.billing
          ? ReturnInfo.returnData!.location.billing.description
          : "",
        "Shipping Address": ReturnInfo.returnData!.location.shipping
          ? ReturnInfo.returnData!.location.shipping.address
          : "",
        "Shipping City": ReturnInfo.returnData!.location.shipping ? ReturnInfo.returnData!.location.shipping.city : "",
        "Shipping State": ReturnInfo.returnData!.location.shipping
          ? ReturnInfo.returnData!.location.shipping.state
          : "",
        "Shipping Postal Code": ReturnInfo.returnData!.location.shipping
          ? ReturnInfo.returnData!.location.shipping.postalCode
          : "",
        "Shipping Country": ReturnInfo.returnData!.location.shipping
          ? ReturnInfo.returnData!.location.shipping.country
          : "",
        "Date Created": ReturnInfo.returnData!.created ? ReturnInfo.returnData!.created.toDate() : "",
        "Date Modified": ReturnInfo.returnData!.updated ? ReturnInfo.returnData!.updated.toDate() : "",
        "Customer Tag": ReturnInfo.returnData!.tag || "",
      })
    })
    workbook.Sheets[workbook.SheetNames[0]] = xlsx.utils.json_to_sheet(sheetJson)
    return workbook
  }

  async buildPDF(pdfConfig: cx.Returns.Return) {
    let warrantyInformation
    try {
      warrantyInformation = await this.warrantyService.fetchWarranty(pdfConfig)
    } catch (error) {
      warrantyInformation = undefined
    }
    const pdf = this.pdfService.buildReturnDocument(pdfConfig, warrantyInformation)
    pdf.save(`${this.$route.params.id} Packing Slip.pdf`)
    this.loadingPDF = false
  }
}
