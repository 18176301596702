
















import { Component, Vue, Prop } from "vue-property-decorator"
import LoadingSpinner from "@/components/LoadingSpinner.vue"

/**
 * Basic Comment Editor creates a comment editor without any formatting options (raw string)
 */
@Component({
  name: "basic-comment-editor",
  components: {
    LoadingSpinner,
  },
})
export default class BasicCommentEditor extends Vue {
  body: string | null = null
  @Prop() loading?: boolean

  comment() {
    this.$emit("create-case-comment", this.body)
    this.body = null
  }
}
