



















import { Component, Vue, Prop } from "vue-property-decorator"
import BasicCommentEditor from "./CommentEditorBasic.vue"
import BasicCommentFeedItem from "./CommentItemBasic.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import * as schema from "@/graphql/__schema"
import getCommentsQuery from "@/graphql/get-case-comments.gql"
import { FetchResult } from "apollo-link"

@Component({
  name: "basic-comment-feed",
  components: {
    BasicCommentEditor,
    BasicCommentFeedItem,
    LoadingSpinner,
  },
})
export default class BasicCommentFeed extends Vue {
  @Prop() fetchComments!: (offset?: number, limit?: number) => Promise<FetchResult<schema.Query>>
  @Prop() createComment!: (commentText: string) => Promise<schema.CaseComment>
  comments: schema.CaseComment[] = []
  commentsLoading: boolean = false
  editorLoading: boolean = false
  total: number = 0

  mounted() {
    this.load()
  }

  load(offset?: number, limit?: number) {
    this.commentsLoading = true
    this.fetchComments(offset, limit)
      .then(result => {
        this.comments.push(...result.data!.allCaseComments!.nodes)
        this.total = result.data!.allCaseComments!.totalCount
      })
      .catch(error => {
        this.$events.emit("show-snackbar", {
          text: "Failed to load return comments",
          color: "error",
        })
      })
      .finally(() => {
        this.commentsLoading = false
      })
  }

  create(body: string) {
    this.editorLoading = true
    this.createComment(body)
      .then(comment => {
        this.comments.unshift(comment)
        this.total++
        this.editorLoading = false
      })
      .catch(error => {
        this.$events.emit("show-snackbar", {
          text: "Failed to create comment",
          color: "error",
        })
        this.editorLoading = false
      })
  }

  loadMore() {
    if (this.total > this.comments.length) {
      this.load(this.comments.length)
    }
  }
}
