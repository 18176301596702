










import _ from "lodash"
import gravatar from "gravatar"
import { Vue, Component, Prop } from "vue-property-decorator"
import { AsyncComputed } from "@/plugins/async-computed"

@Component({
  name: "user-avatar",
})
export default class UserAvatar extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly loading!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly dark!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly tile!: boolean
  @Prop({ type: [Number, String], required: false, default: 40 }) readonly size!: [number, string]
  @Prop({ type: String, required: false }) readonly url!: string
  @Prop({ type: String, required: false }) readonly name!: string
  @Prop({ type: String, required: false }) readonly email!: string
  @Prop({ type: String, required: false, default: "mdi-account" }) readonly icon!: string
  @Prop({
    type: String,
    required: false,
    default: null,
    validator: value => ["url", "gravatar", "characters", "icon"].indexOf(value) !== -1,
  })
  readonly type!: string | null
  @Prop({ type: String, required: false, default: "primary" }) readonly color!: string

  get characters() {
    if (_.isString(this.name)) {
      let nameParts = _.split(this.name, " ")
      let firstCharacter = _.first(_.first(nameParts)) || ""
      if (_.size(nameParts) >= 2) {
        let lastCharacter = _.first(_.last(nameParts)) || ""
        return _.toUpper(firstCharacter + lastCharacter)
      }
      return _.toUpper(firstCharacter)
    }
    return null
  }

  get gravatarUrl() {
    if (_.isString(this.email)) {
      return gravatar.url(this.email, { size: this.size.toString(), rating: "pg", default: "404" }, true)
    }
    return null
  }

  @AsyncComputed()
  async use() {
    if (!_.isNil(this.type)) {
      return this.type
    } else {
      if (_.isString(this.url) && (await this.urlExists(this.url))) {
        return "url"
      } else if (_.isString(this.gravatarUrl) && (await this.urlExists(this.gravatarUrl + "?d=404"))) {
        return "gravatar"
      } else if (_.isString(this.name) && _.isString(this.characters)) {
        return "characters"
      } else {
        if (_.isString(this.icon)) {
          return "icon"
        }
      }
    }
  }

  async urlExists(url: string) {
    try {
      let response = await this.axios.head(url)
      return response.status === 200
    } catch (error) {
      return false
    }
  }
}
