

























import { Component, Vue, Prop } from "vue-property-decorator"
import UserAvatar from "@/components/UserAvatar.vue"

@Component({
  name: "basic-comment-feed-item",
  components: {
    UserAvatar,
  },
  props: {},
})
export default class basicCommentFeedItem extends Vue {
  @Prop() readonly name!: string
  @Prop() readonly text!: string
  @Prop() readonly date!: any
}
