












































import { Vue, Component, Prop } from "vue-property-decorator"
import { Workflow } from "@/graphql/__schema"
import { cx } from "@/types"

import _ from "lodash"
import gql from "graphql-tag"

@Component({
  name: "support-case-workflow",
})
export default class SupportCaseWorkflow extends Vue {
  workflow: { [k: string]: Workflow } | null = null
  workflowMapping: { [k: number]: Workflow } | null = null
  steps: Array<string> | null = null
  currentStep: any = null //TODO: not sure why this reference is needed

  @Prop(Object) readonly returnData!: cx.Returns.Return
  @Prop({ type: Number, required: false, default: 0 }) readonly step!: number
  @Prop({ type: Boolean, required: false, default: false }) readonly showStatus!: boolean

  get stepCount() {
    if (_.isArray(this.steps)) {
      return this.steps.length
    }
    return 0
  }

  get currentWorkflow() {
    if (!_.isNil(this.workflowMapping) && !_.isNil(this.returnData)) {
      let workflowId = (this.returnData.status ? this.returnData.status.workflowId : undefined) || null
      if (!workflowId) return null
      return _.get(this.workflowMapping, workflowId)
    }
    return null
  }

  validateStep(stepIndex: number) {
    return !(
      this.currentWorkflow &&
      this.currentWorkflow.order === stepIndex &&
      this.returnData &&
      this.returnData.status &&
      this.returnData.status.alert
    )
  }

  async querySteps() {
    try {
      let response = await this.$apollo.query({
        query: gql`
          query {
            allWorkflows(filter: { _active: { equalTo: true }, group: { equalTo: "return" } }, orderBy: ORDER_ASC) {
              nodes {
                id
                name
                order
              }
            }
          }
        `,
      })
      let records: Array<Workflow> = response.data.allWorkflows.nodes
      this.workflow = _.keyBy(records, item => item.name)
      this.workflowMapping = _.keyBy(records, item => item.id)
      this.steps = _.keys(this.workflow)
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
    }
  }

  mounted() {
    this.querySteps()
  }
}
